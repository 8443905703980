import PropTypes from 'prop-types'

import {monthDayYear} from '../../../../../lib/dateTools'

import './jiclContactInformation.scss'

const JiclContactInformation = props => (
  <div className='jicl-contact-information'>
    <div className='name'>{props.userName}'s Just-In-Case List</div>
    <div className='updated-at'>
      Updated {monthDayYear(props.lastUpdatedAt, 'MMM DD, YYYY')}
    </div>
    <div className='description'>
      Here's some important information about me that can be helpful in the
      event of an emergency.
    </div>
  </div>
)

JiclContactInformation.propTypes = {
  userName: PropTypes.string,
  lastUpdatedAt: PropTypes.string
}

export default JiclContactInformation
